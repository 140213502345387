import { Social } from '../palnnerhome/Plannerdashbord'
import { Plannerfooter } from '../plannerfooter/Plannerfooter'
import { Plannerheader } from '../plannerhead/Plannerhead'
import '../termscondition/Terms.styles.css'
import React from 'react'

export function Termscontent(props: any) {
  return (
    <div style={{ borderBottom: '3px solid #575757' }}>
      <h4
        className="fw-bold pt-3 text-uppercase text-break"
        style={{ color: '#640281', letterSpacing: '1px' }}
      >
        {props.termsh4}
      </h4>
      <h6 className="fw-bold my-4">{props.termsh6}</h6>
      <p>{props.termsp1}</p>
      <p>{props.termsp2}</p>
      <p>{props.termsp3}</p>
      <p>{props.termsp4}</p>
      <p>{props.termsp5}</p>
      <p>{props.termsp6}</p>
      <p>{props.termsp7}</p>
      <p>{props.termsp8}</p>
      <p>{props.termsp9}</p>
      <p>{props.termsp10}</p>
      <p>{props.termsp11}</p>
      <p>{props.termsp12}</p>
      <p>{props.termsp13}</p>
      <p>{props.termsp14}</p>
      <p>{props.termsp15}</p>
      <p>{props.termsp16}</p>
      <p>{props.termsp17}</p>
      <p>{props.termsp18}</p>
      <p>{props.termsp19}</p>
      <p>{props.termsp20}</p>
      <p>{props.termsp21}</p>
      <p>{props.termsp22}</p>
      <p>{props.termsp23}</p>
      <p>{props.termsp24}</p>
      <p>{props.termsp25}</p>
    </div>
  )
}

export function Privacy() {
  return (
    <div>
      <Plannerheader />
      <Social />

      <div className="col-12 terms-1">
        <img
          alt="privacy-back"
          src="https://thebiharians.com/wp-content/uploads/2020/01/privacy-policy-720.png"
          className="img-flui"
        />
      </div>

      <div className="row justify-content-center bg-light mx-0">
        <div className="col-md-7  py-5 ">
          <h2 className="text-dark" style={{ letterSpacing: '2px' }}>
            𝐏𝐑𝐈𝐕𝐀𝐂𝐘 𝐏𝐎𝐋𝐈𝐂𝐘
          </h2>
          <Termscontent
            termsh4={`Planner Addict`}
            termsp1={`”Planner Addict”, “We” or “Us” respects your privacy and is dedicated to protecting the privacy of those persons You participating in events organized by Planner Addict and using the Planner Addict website and associated services (“Services”). This Privacy Policy helps You to understand what Personal Information (as defined below) and non-personal information We collect, and how We use it.`}
            termsp2={`This Privacy Policy applies to the processing of Personal Information and non-personal information in connection with the Services and You should read this document before using our Services. If You do not agree to the terms of this Privacy Policy, you may not use our Services.`}
            termsp3={`“Personal Information” refers to information, which allows You to be directly or indirectly identified as an individual person. We may also collect “non-personal information” meaning it can’t be used to specifically identify anyone.  Non-personal information may also result from removing the personally identifiable parts from Personal Information. We collect and use both types of information and combinations of both types.`}
          />
          <Termscontent
            termsh4={`Processing Personal Information`}
            termsp1={`Planner Addict will only collect such Personal Information that is relevant for the purposes described in this Privacy Policy.`}
            termsp2={`Planner Addict will process Your Personal Information for the purposes of providing the Services, including organizing the events and facilitating participant networking using the Planner Addict website and the associated services.`}
            termsp3={`Planner Addict will process Your Personal Information to provide customer== service and other services at your request, to send You information,including confirmations, invoices, and other communications, as well as communicate with You about promotions and other news about services offered by Planner Addict.`}
            termsp4={`Planner Addict commits to adhere to the applicable laws and regulations pertaining to processing of Personal Information, as well as to process Personal Information in compliance with good processing practices. All Planner Addict’ personnel processing Personal Information are obliged to keep such information strictly confidential.`}
          />
          <Termscontent
            termsh4={`Legal basis for the processing`}
            termsp1={`The applicable legal basis for the processing of Personal Information depends on the circumstances relating to the relevant processing activities, as further described below:`}
            termsp2={`• If the processing of Personal Information is necessary for one or more specific purposes, GDPR art. 6(1)(a) serves as the legal basis for processing operations.`}
            termsp3={`• Performance of a contract. If the processing of Personal Information is necessary for the performance of a contract, such as for providing certain services, to which the data subject is party, GDPR art. 6(1)(b) serves as the legal basis for processing operations. The same applies to such processing operations which are necessary for carrying out pre-contractual measures, for example in the case of inquiries concerning our products or services.`}
            termsp4={`• Legal obligation. If the processing of Personal Information is necessary for complying with a legal obligation, such as for the fulfillment of tax obligations, GDPR art. 6(1)(c) serves as the legal basis for processing operations.`}
            termsp5={`• Vital interests. If the processing of Personal Information is necessary for protecting the vital interests of a natural person, such as if a visitor were injured in our premises and his information would have to be passed on to medical personnel, GDPR art. 6(1)(d) serves as the legal basis for processing operations.`}
            termsp6={`• Legitimate interests. If the processing of Personal Information is necessary for processing operations which are not covered by any of the abovementioned legal grounds, but are deemed permissible for the purposes of the legitimate interests pursued by Us, such as marketing, GDPR art. 6(1)(f) serves as the legal basis for processing operations.`}
          />
          <Termscontent
            termsh4={`Information We Collect and Sources of Information`}
            termsp1={`We collect several categories of information:`}
            termsp2={`• Information You give to us,`}
            termsp3={`• Technically gathered information when You use our Services,`}
            termsp4={`• Information from third parties in relation to our service.`}
            termsp5={`We collect information that You give to us, for example when You use our Services. This information may include your name and email. On our website, You are also given the opportunity to subscribe to our newsletter.`}
            termsp6={`Planner Addict also collects non-personal information on Your activities in the Services, such as your Internet Protocol address, and time of Your requests or actions in the website and referring website addresses, hardware type, operating system version, browser type, and language.`}
            termsp7={`Planner Addict also collects non-personal information on Your activities in the Services, such as your Internet Protocol address, and time of Your requests or actions in the website and referring website addresses, hardware type, operating system version, browser type, and language.
            Planner Addict retains the Personal Information collected pursuant to this Privacy Policy for the period necessary to fulfil the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law. The criteria used to determine the period of storage of Personal Information is depends on the applicable legal basis for the processing of Personal Information: e.g. where the processing of Personal Information is necessary for the performance of a contract, Planner Addict retains the Personal Information as long as it is necessary for the fulfillment of the contract or the initiation of a contract; or, where the processing of Personal Information is necessary for complying with a legal obligation, Planner Addict retains the Personal Information for the respective statutory retention period; or, where the processing of Personal Information is permissible for the purposes of the legitimate interests pursued by Us, such as marketing, Planner Addict retains the Personal Information as long as it is permitted by law.`}
            termsp8={`Thereafter, if the collected Personal Information is no longer needed for purposes specified in this Privacy Policy, Planner Addict will delete Personal Information in its possession within a reasonable timeframe or render it anonymous, meaning “non-personal information”.`}
            termsp9={`We also use other third-party data analytics service providers in order to improve the Services Planner Addict may use tools from third-party service providers, such as Google Analytics, a web analytics service provided by Google, Inc. (“Google”).`}
            termsp10={`Google Analytics uses “cookies”, which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.`}
            termsp11={`The Google Analytics tracking code implemented on this website supports Display Advertising. Google Analytics for Display Advertising is used on this website in form of Remarketing. Third-party vendors, including Google, will show Planner Addict ads on sites across the internet. Planner Addict and third-party vendors, including Google, use first-party cookies (such as the Google Analytics cookie) and third-party cookies (such as the DoubleClick cookie) together to inform, optimize and serve ads based on your past visits to this website. You can opt out of Google Analytics for Display Advertising and customize Google Display ads using the Ads Preferences Manager.`}
            termsp12={`The following Google AdWords features are used on this website:`}
            termsp13={`• Remarketing`}
            termsp14={`• Interest Categories`}
            termsp15={`• Similar Audiences`}
            termsp16={`•  Other types of interest-based advertising`}
            termsp17={`We use these features of Google Adwords to target past visitors across the internet or visitors with specific interest profiles based on their internet usage. We don’t collect personally identifiable information (PII) through our cookies, remarketing lists, or any other anonymous identifiers. `}
            terms18={`The data collected by data analytics service providers enables us to understand usage of the Services. Usage information and Personal Information are stored by such service providers and are subject to their privacy policies.`}
            termsp19={`Information collected by advertising networks, such as Facebook Audience Network, shall be used in accordance with this Privacy Policy and the privacy policy of the third party providing applicable technology. If You want to be sure that no targeted advertisements will be displayed to You please do not use or access the Services. In addition, please note that if You “opt out” of targeted advertising, You will still receive advertising that is not personalized to You.`}
          />
          <Termscontent
            termsh4={`Disclosing and Hosting Information`}
            termsp1={`Planner Addict will not disclose your stored Personal Information to third parties for direct electric marketing (email, text messages) without your consent or except as part of a specific program or feature for which You will have the ability to opt-in or opt-out. You may always opt-out of receiving promotional emails by following the instructions in those emails. If You opt-out, Planner Addict may still send You non-promotional customer information, such as emails about providing the Service or Planner Addict’ ongoing business relations.`}
            termsp2={`We use external service providers to provide technical solutions or services for processing stored information and access the stored information by using a technical interface and share your personally information with third-party service providers to the extent that it is reasonably necessary to perform, improve or maintain the Services. We use third-party service providers, such as e-mail service providers, credit card processors, information analyzers and business intelligence providers. Planner Addict has right to share Personal Information as necessary for the aforementioned service providers to provide their services to Planner Addict. Planner Addict is not liable for the acts and omissions of these third parties. Planner Addict may host the Services in and transfer Personal Information for this purpose to countries outside the European Union or the European Economic Area in accordance with mandatory legislation and this Privacy Policy.`}
            termsp3={`We has right to share non-personal information (such as anonymous usage data, referring / exit pages and URLs, platform types, asset views, number of clicks, etc.) with interested third parties to assist them in understanding the usage patterns for certain content, services, advertisements, promotions, and/or functionality of the Services. In exceptional circumstances Personal Information may be disclosed to third parties if required under any applicable law or regulation or order by competent authorities, or in order to enforce the Terms of Service and to investigate possible infringing use of the Services as well as to guarantee the safety of the Services.`}
            termsp4={`Planner Addict has right to share your information in connection with any merger, sale of our assets, or a financing or acquisition of all or a portion of our business. Aggregate or anonymous information about You may be shared with advertisers, publishers, business partners, and other third parties.`}
          />
          <Termscontent
            termsh4={`Protection of Information`}
            termsp1={`The registers will be maintained in external service providers’ servers with appropriate safeguards, such as password protection, granting the access to the stored information only to persons working at Planner Addict or Planner Addict partners who are expressly authorized by Planner Addict. The register is protected by appropriate industry standard, technical and organizational safety measures. Although we make good faith efforts to store the information collected on the services in a secure operating environment that is not available to the public, We cannot guarantee the absolute security of that information during its transmission or its storage on our systems. Planner Addict will post a notice on the Planner Addict website or through the Services in case material security breach that endangers your privacy or Personal Information. Planner Addict may also temporarily shut down a service to protect Personal Information.`}
          />
          <Termscontent
            termsh4={`Cookies and Tracking Technologies`}
            termsp1={`A cookie is a string of information or a small text file that a website stores on a visitor’s device, and that the visitor’s browser or operating system provides to “remember” things about your visit. Planner Addict uses cookies to help it identify and track visitors, their usage of the Services, and their access preferences, improving quality, tailoring recommendations, and developing the Services. The cookies will not enable Planner Addict to access and review information stored on Your computer. In addition to cookies, Planner Addict may use other existing or later developed tracking technologies.`}
            termsp2={`Planner Addict works with third parties who use cookies and other tracking technologies to track the effectiveness of the advertisements that may be placed in the Services. They are used for analyzing advertisement view counts. They might also allow the advertiser to tailor advertising to you when you visit other websites, and/or set, change, alter or modify settings or configurations on your device.`}
            termsp3={`If You do not wish to have cookies placed on Your computers, You should set the devices to refuse cookies or tracking before using the Services, with the drawback that certain features of such service may not function properly.`}
            termsp4={`Please note that some parts of the Services may be country specific, and may not work properly if Planner Addict is unable to tell where You are accessing the Services from.`}
          />
        </div>
      </div>
      <Plannerfooter />
    </div>
  )
}
