import AWS from '../cart/awsConfig'

const sendEmail = (formData) => {
  try {
    const ses = new AWS.SES({ apiVersion: '2010-12-01' })

    const params = {
      Destination: {
        ToAddresses: ['info@planneraddict.in'], // Replace with your recipient's email address
      },
      Message: {
        Body: {
          Text: {
            Data: `Name: ${formData.name}\nEmail: ${formData.email}\nNumber: ${formData.number}\nMessage: ${formData.message}`, // Include the 'Number' field in the email content
          },
        },
        Subject: {
          Data: 'New Contact Form Submission',
        },
      },
      Source: 'info@planneraddict.in', // Replace with the verified sender email address
    }

    return ses.sendEmail(params).promise()
  } catch (error) {
    console.error('Error sending email:', error)
    throw error
  }
}

export default sendEmail
