// awsConfig.js
import AWS from 'aws-sdk'

AWS.config.update({
  region: 'ap-south-1', // Replace with your AWS region (e.g., 'us-east-1')
  accessKeyId: 'AKIAUPCN2CNRANKQSI5S',
  secretAccessKey: 'Citj889ZRSYvb42Z0rbJtcWmejQBDhAuJ7L372oo',
})

export default AWS
