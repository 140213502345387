import '../categories/Categories.styles.css'
import { Social } from '../palnnerhome/Plannerdashbord'
import { Plannerfooter } from '../plannerfooter/Plannerfooter'
import { Plannerheader } from '../plannerhead/Plannerhead'
import Categoriesimg from './Birthday'
import React from 'react'

export function Reception() {
  return (
    <>
      <div className="catergories">
        <Plannerheader></Plannerheader>
        <Social />

        <div className="reception"></div>
        <div className="col-12  catergoriesimg">
          <h1 className="col">༺MARRIAǤE RECEᖘTION༻</h1>

          <div className="mx-0 px-0 d-flex justify-content-center mt-5">
            <div className="reception-container">
              <div className="reception-arrow">
                <label htmlFor="reception-page1" className="reception-tab">
                  <i className="fs-4 bi bi-arrow-left-circle-fill"></i>
                </label>
                <label htmlFor="reception-page2" className="reception-tab">
                  <i className="fs-4 bi bi-arrow-right-circle-fill"></i>
                </label>
              </div>
              <input type="radio" name="page" id="reception-page1" checked />
              <div className="reception-content" id="reception-content1">
                <div className="row mx-0 firstrow mt-1 g-4 p-md-5">
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl317.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl318.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl319.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl320.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl321.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level1/receptionl5.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 2/receptionl210.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 2/receptionl211.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl317.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl318.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level1/receptionl6.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level1/receptionl7.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level1/receptionl8.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level1/receptionl9.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level1/receptionl10.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level1/receptionl11.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level1/receptionl12.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level1/receptionl13.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level1/receptionl14.png')}
                  />
                  {/* <Categoriesimg
                                            categoriesimg={require('../../asset/marriage/reception/level 2/receptionl21.png')}
                                        /> */}
                  {/* <Categoriesimg
                                            categoriesimg={require('../../asset/marriage/reception/level 2/receptionl23.png')}
                                        /> */}
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 2/receptionl24.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 2/receptionl25.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 2/receptionl26.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 2/receptionl27.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 2/receptionl28.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 2/receptionl21.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 2/receptionl29.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level1/receptionl1.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level1/receptionl2.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level1/receptionl3.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level1/receptionl4.png')}
                  />

                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl334.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl335.png')}
                  />
                </div>
              </div>

              <input type="radio" name="page" id="reception-page2" />
              <div className="reception-content" id="reception-content2">
                <div className="row mx-0 firstrow g-4 mt-1 p-md-5">
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl324.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl325.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl326.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl327.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl328.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl329.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl330.png')}
                  />

                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl331.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl332.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl333.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 2/receptionl212.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 2/receptionl213.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 2/receptionl214.png')}
                  />
                  {/* <Categoriesimg
                                            categoriesimg={require('../../asset/marriage/reception/level 2/receptionl215.png')}
                                        /> */}
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 2/receptionl216.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl31.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl32.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl33.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl34.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl35.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl36.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl37.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl38.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl39.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl310.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl311.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl312.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl313.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl314.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl315.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl316.png')}
                  />

                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl322.png')}
                  />
                  <Categoriesimg
                    categoriesimg={require('../../asset/marriage/reception/level 3/receptionl323.png')}
                  />

                  {/* <Categoriesimg
                      categoriesimg={require('../../asset/marriage/reception/level 3/receptionl316.png')}
                    /> */}
                  {/* <Categoriesimg
                      categoriesimg={require('../../asset/planner-logo.png')}
                    /> */}
                  {/* <div
                      className="col-12 d-flex justify-content-center"
                      style={{ boxShadow: 'none' }}
                    >
                      <img
                        className="img-fluid"
                        src={require('../../asset/planner-logo.png')}
                        alt=""
                        style={{ height: 150, width: 150 }}
                      />
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Plannerfooter></Plannerfooter>
      </div>
    </>
  )
}
