import '../cart/Cart.styles.css'
import { Plannerheader } from '../plannerhead/Plannerhead'
import { Plannerfooter } from '../plannerfooter/Plannerfooter'
import React, { useState } from 'react'
import sendEmail from '../cart/EmailSender'
import { Social } from '../palnnerhome/Plannerdashbord'

export const Contactus = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    message: '',
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      setIsSubmitting(true)
      await sendEmail(formData)
      // Send the email using AWS SES
      setIsSubmitted(true)
      setTimeout(() => {
        setIsSubmitted(false)
      }, 5000)
      setFormData({
        name: '',
        email: '',
        number: '',
        message: '',
      })
    } catch (error) {
      console.error('Error sending email:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className="vh-100">
      <Plannerheader />
      <Social />

      <div className="col-12 ">
        <div className="cart-head">
          <div className="col-12 cart-head-img"></div>
          <div
            className="contactus-text col-12 col-xl-6"
            data-aos="slide-right"
          >
            <h1>𝐂𝐎𝐍𝐓𝐀𝐂𝐓 𝐔𝐒</h1>
          </div>
        </div>

        <div className="col-12 cart-contact text-center">
          <div className="col-xl-10 col-12">
            <h3 data-aos="slide-up">
              𝐏𝐥𝐚𝐧𝐧𝐞𝐫 𝐀𝐝𝐝𝐢𝐜𝐭 - 𝐘𝐨𝐮𝐫 𝐏𝐞𝐫𝐬𝐨𝐧𝐚𝐥𝐢𝐳𝐞𝐝 𝐄𝐯𝐞𝐧𝐭 𝐌𝐚𝐧𝐚𝐠𝐞𝐦𝐞𝐧𝐭 𝐂𝐨𝐦𝐩𝐚𝐧𝐲
            </h3>
            <h2 data-aos="slide-up">𝓟𝓵𝓪𝓷 𝔂𝓸𝓾𝓻 𝔀𝓮𝓭𝓭𝓲𝓷𝓰 𝔀𝓲𝓽𝓱 𝓤𝓼</h2>
            <p className="mt-3" data-aos="slide-up">
              PlannerAddict is your personal wedding planning site. Browse
              through the site to find vendors for your wedding. Explore wedding
              inspiration & ideas and our very popular wedding blog to prepare
              for your wedding following the latest trends. Contact us for more
              details.
            </p>
          </div>
        </div>

        <div className="row mx-0 ">
          <div className="col-lg-7 col-12">
            <form id="contact" className="contact-form" onSubmit={handleSubmit}>
              <div className="form-back"></div>
              <div className="col-lg-5 col-11 segment"> </div>

              <div className="segment1 bg-dange" data-aos="zom-in">
                <h1>𝐅𝐢𝐥𝐥 𝐘𝐨𝐮𝐫 𝐈𝐧𝐟𝐨𝐫𝐦𝐚𝐭𝐢𝐨𝐧</h1>
                <h6 className="col-lg-9 col-11 text-center">
                  𝐖𝐞 𝐰𝐨𝐮𝐥𝐝 𝐥𝐨𝐯𝐞 𝐭𝐨 𝐡𝐞𝐚𝐫 𝐟𝐫𝐨𝐦 𝐲𝐨𝐮! 𝐂𝐨𝐧𝐭𝐚𝐜𝐭 𝐮𝐬 𝐝𝐢𝐫𝐞𝐜𝐭𝐥𝐲 𝐛𝐲 𝐟𝐢𝐥𝐥𝐢𝐧𝐠
                  𝐭𝐡𝐢𝐬 𝐟𝐨𝐫𝐦.
                </h6>
                <input
                  className="col-lg-8 col-md-11 col-11"
                  type="text"
                  name="name"
                  placeholder="Name"
                  required
                  value={formData.name}
                  onChange={handleChange}
                />

                <input
                  className="col-lg-8 col-md-11 col-11"
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
                <input
                  className="col-lg-8 col-md-11 col-11"
                  type="number"
                  name="number"
                  placeholder="Phone Number"
                  required
                  value={formData.number}
                  onChange={handleChange}
                />
                <textarea
                  className="col-lg-8 col-md-11 col-11"
                  type="text"
                  name="message"
                  placeholder="Your Opinion"
                  required
                  style={{ height: '100px' }}
                  value={formData.message}
                  onChange={handleChange}
                />
                <div className="col-12 d-flex flex-column align-items-center">
                  <button
                    disabled={isSubmitting}
                    value="Send"
                    className="mt-4"
                    type="submit"
                  >
                    {isSubmitting ? 'SUBMIT' : 'SUBMIT'}
                    <i className="mx-1 fa fa-paper-plane"></i>
                  </button>
                  <br />
                  <div className="col-sm-8 col-11 text-center m-0 p-0">
                    {isSubmitted && (
                      <p className=" success-message p-0 m-0">
                        Form submitted successfully!
                      </p>
                    )}
                  </div>

                  {/* <p className="mt-4 success-message">
                    Form submitted successfully!
                  </p> */}
                </div>
              </div>
            </form>
          </div>

          <div className="col-lg-5 p-lg-0 px-md-5  col-12 contactus-content">
            <div className="col-12">
              <h4 data-aos="slide-up">Vendors</h4>
              <p data-aos="slide-up">
                If you are a registered vendor or a business looking to promote
                your brand on our portal, please send in your queries at vendors{' '}
                <span> info@planneraddict.in</span>
              </p>
            </div>

            <div className="col-12">
              <h4 data-aos="slide-up">Marketing Collaborations</h4>
              <p data-aos="slide-up">
                For brand collaborations - sponsored content, social media
                activations etc., please write into partnerships{' '}
                <span> info@planneraddict.in</span>
              </p>
            </div>

            <div className="col-12">
              <h4 data-aos="slide-up">Careers</h4>
              <p data-aos="slide-up">
                We are a team of passionate young minds looking to reinvent the
                wedding space. If any one intrested please email us at{' '}
                <span> info@planneraddict.in</span>
              </p>
            </div>

            <div className="col-12">
              <h4 data-aos="slide-up">Customers</h4>
              <p data-aos="slide-up">
                We love to hear from our precious users. For any feedback or
                queries simply write in to info{' '}
                <span> info@planneraddict.in</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Plannerfooter />
    </div>
  )
}
