import React, { useState } from 'react'
import '../plannerhead/Plannerhead.styles.css'
import 'mdb-react-ui-kit/dist/css/mdb.min.css'

export function Plannerheader() {
  const [showNavbar, setShowNavbar] = useState(false)

  const openNavbar = () => {
    setShowNavbar(true)
  }

  const closeNavbar = () => {
    setShowNavbar(false)
  }

  return (
    <nav className={`navbar navbar-expand-lg ${showNavbar ? 'open' : ''}`}>
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img
            className="logo"
            src={require('../../asset/logo.png')}
            alt="Logo"
          />
          <img
            className="logoname"
            src={require('../../asset/logoname.png')}
            alt="Logo Name"
          />
        </a>
        <button
          onClick={showNavbar ? closeNavbar : openNavbar}
          className="navbar-toggler"
          style={{ color: 'green' }}
          type="button"
          aria-label="Toggle navigation"
        >
          <div className={`animated-icon1 ${showNavbar ? 'open' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        <div className={`collapse navbar-collapse ${showNavbar ? 'show' : ''}`}>
          <ul className="navbar-nav">
            <li className="nav-item  borderYtoX ">
              <a className="nav-link " href="/">
                𝐇𝐎𝐌𝐄
              </a>
            </li>

            <li className="nav-item borderYtoX ">
              <a className="nav-link" href="/about">
                𝐀𝐁𝐎𝐔𝐓 𝐔𝐒
              </a>
            </li>

            <li className="nav-item dropdown ">
              <a className="nav-link dropdown-toggle " href="#">
                𝐂𝐀𝐓𝐄𝐆𝐎𝐑𝐈𝐄𝐒
              </a>

              <ul className="dropdown-menu p-0">
                <li>
                  <a href="/birthday" className="dropdown-item">
                    BIRTHDAY EVENT
                  </a>
                </li>

                <li>
                  <a href="/naming" className="dropdown-item">
                    NAMING CEREMONY
                  </a>
                </li>
                <li>
                  <a href="/keeth" className="dropdown-item text-wrap">
                    CHAPPARAM
                  </a>
                </li>
                <li className="dropdown-submenu p-0">
                  <a className="dropdown-item dropdown-toggle">MARRIAGE</a>
                  <ul className="dropdown-menu p-0">
                    <li>
                      <a className="dropdown-item" href="/haldi">
                        HALDI DECORATION
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/reception">
                        RECEPTION
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/mantapa">
                        MANTAPA
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="nav-item   borderYtoX">
              <a className="nav-link" href="/contactus">
                𝐂𝐎𝐍𝐓𝐀𝐂𝐓 𝐔𝐒
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
