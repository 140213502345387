import React from 'react'

import '../aboutus/About.styles.css'
import { Plannerheader } from '../plannerhead/Plannerhead'
import { Plannerfooter } from '../plannerfooter/Plannerfooter'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, EffectCoverflow, Pagination } from 'swiper/core'

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/effect-coverflow/effect-coverflow.min.css'
import 'swiper/components/pagination/pagination.min.css'
import { Social } from '../palnnerhome/Plannerdashbord'

SwiperCore.use([Autoplay, EffectCoverflow, Pagination])

export function About() {
  return (
    <div className="about">
      <Plannerheader></Plannerheader>
      <Social />

      <div className="aboutus-head-img">
        <div className="aboutus-img"></div>
        <div className="aboutustext col-md-5 col-12">
          <h1 className="col" data-aos="slide-down">
            𝐀𝐁𝐎𝐔𝐓 𝐔𝐒
          </h1>
        </div>
      </div>

      <div className="container mt-3 text-center p-1">
        <h1 style={{ color: '#612f61' }} data-aos="slide-right">
          𝐏𝐋𝐀𝐍𝐍𝐄𝐑 𝐀𝐃𝐃𝐈𝐂𝐓
        </h1>
        <p className="mt-lg-4" data-aos="slide-up">
          𝐎𝐮𝐫 𝐏𝐚𝐫𝐞𝐧𝐭 𝐂𝐨𝐦𝐩𝐚𝐧𝐲 𝐑𝐨𝐲𝐚𝐥 𝐒𝐨𝐮𝐧𝐝 𝐒𝐲𝐬𝐭𝐞𝐦 𝐰𝐡𝐢𝐜𝐡 𝐢𝐬 𝐞𝐬𝐭𝐚𝐛𝐥𝐢𝐬𝐡𝐞𝐝 𝟏𝟗𝟒𝟔 𝐁𝐚𝐬𝐞𝐝
          𝐨𝐧 𝐒𝐚𝐥𝐞𝐦 𝐓𝐍. 𝐖𝐞 𝐚𝐫𝐞 𝐭𝐡𝐞 𝐅𝐢𝐫𝐬𝐭 𝐭𝐨 𝐈𝐧𝐭𝐫𝐨𝐝𝐮𝐜𝐞 𝐭𝐡𝐞 𝐋𝐚𝐭𝐞𝐬𝐭 𝐓𝐞𝐜𝐡𝐧𝐨𝐥𝐨𝐠𝐲 𝐭𝐨
          𝐭𝐡𝐞 𝐦𝐚𝐫𝐤𝐞𝐭 𝐢𝐧 𝐄𝐚𝐫𝐥𝐲 𝐬𝐭𝐚𝐠𝐞𝐬. 𝐀𝐥𝐥 𝐨𝐯𝐞𝐫 𝐬𝐨𝐮𝐭𝐡 𝐈𝐧𝐝𝐢𝐚 𝐎𝐮𝐫 𝐏𝐫𝐨𝐟𝐞𝐬𝐬𝐢𝐨𝐧 𝐡𝐚𝐬
          𝐜𝐨𝐦𝐞 𝐚𝐜𝐫𝐨𝐬𝐬 𝐦𝐚𝐧𝐲 𝐠𝐞𝐧𝐞𝐫𝐚𝐭𝐢𝐨𝐧 𝐒𝐢𝐧𝐜𝐞 𝐎𝐮𝐫 𝐩𝐫𝐨𝐟𝐞𝐬𝐬𝐢𝐨𝐧 𝐡𝐚𝐬 𝐞𝐱𝐩𝐚𝐧𝐝𝐞𝐝 𝐛𝐚𝐬𝐞𝐝 𝐨𝐧
          𝐂𝐮𝐬𝐭𝐨𝐦𝐞𝐫 𝐛𝐚𝐬𝐞𝐝 𝐫𝐞𝐪𝐮𝐢𝐫𝐞𝐦𝐞𝐧𝐭𝐬. 𝐎𝐧 𝐭𝐡𝐞 𝐒𝐩𝐞𝐜𝐢𝐟𝐢𝐜 𝐒𝐞𝐭𝐮𝐩. 𝐖𝐢𝐭𝐡 𝐎𝐮𝐫 𝐩𝐫𝐞𝐯𝐢𝐨𝐮𝐬
          𝐞𝐱𝐩𝐞𝐫𝐢𝐞𝐧𝐜𝐞 𝐚𝐧𝐝 𝐰𝐞𝐥𝐥 𝐤𝐧𝐨𝐰 𝐧𝐞𝐭𝐰𝐨𝐫𝐤. 𝐈𝐧 𝟐𝟎𝟏𝟗, 𝐰𝐞 𝐬𝐭𝐚𝐫𝐭𝐞𝐝 𝐨𝐮𝐫 𝐧𝐞𝐰 𝐉𝐨𝐮𝐫𝐧𝐞𝐲
          𝐭𝐨𝐰𝐚𝐫𝐝𝐬 𝐞𝐯𝐞𝐧𝐭 𝐦𝐚𝐧𝐚𝐠𝐞𝐦𝐞𝐧𝐭 𝐢𝐧 𝐭𝐡𝐞 𝐧𝐚𝐦𝐞 𝐨𝐟 "𝐏𝐥𝐚𝐧𝐧𝐞𝐫 𝐀𝐝𝐝𝐢𝐜𝐭" 𝐡𝐞𝐫𝐞 𝐢𝐧
          𝐁𝐞𝐧𝐠𝐚𝐥𝐮𝐫𝐮 𝐰𝐢𝐭𝐡 𝐀𝐥𝐥 𝐎𝐮𝐫 𝐥𝐨𝐯𝐞 𝐚𝐧𝐝 𝐁𝐥𝐞𝐬𝐬𝐢𝐧𝐠𝐬.
        </p>
      </div>

      <div className="pp mt-lg-5 mt-2" data-aos="zoom-in">
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 40,
            stretch: 0,
            depth: 200,
            modifier: 1,
            slideShadows: true,
          }}
          loop={true}
          pagination={true}
          className="mySwiper"
          autoplay={{
            delay: 950,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <img
              src={require('../../asset/marriage/reception/level1/receptionl7.png')}
              alt="img"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={require('../../asset/marriage/reception/level1/receptionl8.png')}
              alt="img"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={require('../../asset/marriage/reception/level1/receptionl9.png')}
              alt="img"
            />
          </SwiperSlide>

          <SwiperSlide>
            <img
              src={require('../../asset/marriage/reception/level1/receptionl13.png')}
              alt="img"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={require('../../asset/marriage/reception/level1/receptionl10.png')}
              alt="img"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={require('../../asset/marriage/reception/level1/receptionl11.png')}
              alt="img"
            />
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="row mx-0 justify-content-center p-2 p-md-0 mt-5">
        <div className="col-md-10 text-center four-pics-content">
          {/*  */}
          <h5>𝐈𝐦𝐩𝐨𝐫𝐭𝐚𝐧𝐜𝐞 𝐎𝐟 𝐌𝐚𝐤𝐢𝐧𝐠 𝐏𝐥𝐚𝐧𝐧𝐢𝐧𝐠 & 𝐝𝐞𝐜𝐢𝐬𝐢𝐨𝐧𝐬</h5>

          <p className="px-lg-4" data-aos="slide-up">
            Planning a successful event involves making a number of decisions
            that will impact everything from the overall theme and tone of the
            event, to the logistics of how it will be run. Some key factors to
            consider when making planning decisions for an event include:
          </p>
          <div className="text-start">
            <p className="px-lg-4" data-aos="slide-up">
              1: 𝗣𝘂𝗿𝗽𝗼𝘀𝗲 : Clearly define the purpose of the event, and ensure
              that all planning decisions align with that purpose.
            </p>
            <p className="px-lg-4" data-aos="slide-up">
              2: 𝗕𝘂𝗱𝗴𝗲𝘁 : Determine how much money you have to work with, and
              allocate your resources accordingly.
            </p>
            <p className="px-lg-4" data-aos="slide-up">
              3: 𝗧𝗮𝗿𝗴𝗲𝘁 𝗮𝘂𝗱𝗶𝗲𝗻𝗰𝗲 : Consider who you are trying to reach with
              your event, and tailor your planning decisions to appeal to that
              audience.
            </p>

            <p className="px-lg-4" data-aos="slide-up">
              4: 𝗟𝗼𝗰𝗮𝘁𝗶𝗼𝗻 : Choose a location that is convenient for your target
              audience, and that has the amenities and facilities you need to
              host your event.
            </p>

            <p className="px-lg-4" data-aos="slide-up">
              5: 𝗧𝗶𝗺𝗶𝗻𝗴: Consider the best time of year to hold your event,
              based on factors such as weather, competing events, and your
              target audience's availability.
            </p>
            <p className="px-lg-4" data-aos="slide-up">
              6: 𝗙𝗼𝗿𝗺𝗮𝘁: Decide on the format of your event, such as whether it
              will be a conference, workshop, or social gathering, and plan
              accordingly.
            </p>
            <p className="px-lg-4" data-aos="slide-up">
              7: 𝗣𝗿𝗼𝗺𝗼𝘁𝗶𝗼𝗻 : Develop a promotional plan to ensure that your
              event gets the visibility it needs to be successful.
            </p>
          </div>
          <p className="px-lg-4" data-aos="slide-up">
            By considering these factors and making informed planning decisions,
            you can set your event up for success.
          </p>
          {/*  */}
          <h5 className="mt-3 p-2" data-aos="slide-up">
            𝐎𝐔𝐑 𝐄𝐕𝐄𝐍𝐓 𝐌𝐀𝐍𝐀𝐆𝐄𝐌𝐄𝐍𝐓 𝐏𝐇𝐈𝐋𝐎𝐒𝐎𝐏𝐇𝐘
          </h5>
          <p className="px-lg-4" data-aos="slide-up">
            The philosophy of event management refers to the underlying
            principles and values that guide the planning and execution of
            events. Some key elements of a philosophy of event management might
            include:
          </p>
          <div className="text-start">
            <p className="px-lg-4" data-aos="slide-up">
              ★ Ensuring that the event meets the needs and expectations of
              attendees
            </p>
            <p className="px-lg-4" data-aos="slide-up">
              ★ Creating a positive and memorable experience for attendees
            </p>
            <p className="px-lg-4" data-aos="slide-up">
              ★ Being mindful of the environmental and social impact of the
              event
            </p>
            <p className="px-lg-4" data-aos="slide-up">
              ★ Being responsible and transparent in financial and logistical
              planning
            </p>
            <p className="px-lg-4" data-aos="slide-up">
              ★ Promoting inclusivity and diversity among attendees and event
              staff
            </p>
            <p className="px-lg-4" data-aos="slide-up">
              ★ Fostering a collaborative and professional working environment
              among the event team
            </p>
          </div>
          <p className="px-lg-4" data-aos="slide-up">
            An event management philosophy should be reflected in every aspect
            of the event, from the planning process to the execution and
            follow-up. By adhering to a clear set of guiding principles, event
            organizers can ensure that their events are successful, ethical, and
            enjoyable for all involved
          </p>
          {/*  */}
          <h5 className="mt-3 p-2" data-aos="slide-up">
            𝐎𝐔𝐑 𝐄𝐕𝐄𝐍𝐓 𝐌𝐀𝐍𝐀𝐆𝐄𝐌𝐄𝐍𝐓 𝐒𝐓𝐑𝐀𝐓𝐄𝐆𝐘
          </h5>
          <p className="px-lg-4" data-aos="slide-up">
            Event management is the process of planning, organizing, and
            executing events such as conferences, concerts, festivals, and
            conventions. It involves a range of activities,Effective event
            management requires strong organizational skills, attention to
            detail, and the ability to think on one's feet and problem-solve in
            a fast-paced environment. It also involves working with a team of
            professionals, including event planners, vendors, and volunteers, to
            ensure that the event runs smoothly and meets the needs of
            attendees.
          </p>
        </div>
        <div className="col-12 fourpics m-5">
          <img
            data-aos="slide-up"
            src={require('../../asset/marriage/haldi/haldi1.png')}
            alt="img"
          />

          <img
            data-aos="slide-up"
            src={require('../../asset/marriage/haldi/haldi8.png')}
            alt="img"
          />

          <img
            data-aos="slide-up"
            src={require('../../asset/marriage/reception/level 2/receptionl26.png')}
            alt="img"
          />

          <img
            data-aos="slide-up"
            src={require('../../asset/marriage/reception/level 2/receptionl29.png')}
            alt="img"
          />

          {/* <div className="row mx-0 mt-3" data-aos="slide-up "> */}
          {/* <div className=" col-12">
            <img
              data-aos="slide-up"
              src={require("../../asset/marriage/reception/level 2/receptionl26.png")}
              alt="img"
            />
          </div>
          <div className="col-12">
            <img
              data-aos="slide-up"
              src={require("../../asset/marriage/reception/level 2/receptionl29.png")}
              alt="img"
            />
          </div> */}
          {/* </div> */}
        </div>
      </div>

      <Plannerfooter></Plannerfooter>
    </div>
  )
}
