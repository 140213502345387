import '../palnnerhome/Plannerdashboard.styles.css'
import React, { useEffect } from 'react'

import { Plannerfooter } from '../plannerfooter/Plannerfooter'
import { Plannerheader } from '../plannerhead/Plannerhead'

export function Social(props: any) {
  return (
    <div className="icon-bar">
      <a href="tel:9353583558" className="telephone">
        <i className="bi bi-telephone-fill"></i>
      </a>
      <a href="/contactus#contact" className="envelope">
        <i className="bi bi-envelope"></i>
      </a>
      <a href="https://wa.me/9353583558" className="whatsapp">
        <i className="bi bi-whatsapp"></i>
      </a>
      <a
        href="https://www.instagram.com/planneraddict.in/?hl=en"
        className="instagram"
      >
        <i className="bi bi-instagram"></i>
      </a>
      <a href="https://www.facebook.com/Planneraddict/" className="facebook">
        <i className="bi bi-facebook"></i>
      </a>
      <a href="https://mobile.twitter.com/planneraddict_" className="twitter">
        <img
          className="img-fluid bi-twitter-x"
          src="https://assets.ifttt.com/images/channels/2/icons/monochrome_large.png"
          alt=""
        />
      </a>
    </div>
  )
}

export function Services(props: any) {
  return (
    <div className="col-lg-2">
      <div className="services-card text-break">
        <img
          src={props.serviceimg}
          className="services-card-img "
          alt="Cards"
        />
        <p className="card-p col-12 ">{props.servicename}</p>
      </div>
    </div>
  )
}

export function Categories(props: any) {
  return (
    <div
      data-aos="slide-up"
      className="col-md-6 p-2 mt-sm-4 mt-1 our-categories"
    >
      <div className={props.option}>
        <a
          className="d-flex align-items-center  justify-content-between"
          href={props.href}
        >
          <div className="col-md-8 col-7">
            <h2>{props.h1}</h2>
            <p>{props.p}</p>
          </div>
          <div className="img-div col d-flex justify-content-end">
            <img className="img-fluid" src={props.img} alt="catergories" />
          </div>
        </a>
      </div>
    </div>
  )
}

export function Plannerdashboard() {
  useEffect(() => {
    const parentDivs = document.querySelectorAll('.parent')

    parentDivs.forEach((parent) => {
      const childDiv = parent.querySelector('.child')
      const parentID = parent.id

      const storedState = localStorage.getItem(parentID)
      if (childDiv) {
        childDiv.classList.toggle('hidden', storedState === 'true')
      }

      parent.addEventListener('click', () => {
        if (childDiv) {
          childDiv.classList.toggle('hidden')
          const isChildHidden = childDiv.classList.contains('hidden')
          localStorage.setItem(parentID, JSON.stringify(isChildHidden))
        }
      })
    })

    parentDivs.forEach((parent) => {
      const childDiv = parent.querySelector('.child')
      if (childDiv) {
        childDiv.classList.add('hidden')
      }
    })

    return () => {
      parentDivs.forEach((parent) => {
        const childDiv = parent.querySelector('.child')
        if (childDiv) {
          childDiv.classList.add('hidden')
          localStorage.setItem(parent.id, JSON.stringify(true))
        }
      })
    }
  }, [])
  return (
    <div className="container-fluid px-0">
      <Plannerheader />
      <>
        <Social />
        <div className="col-sm-12 mainhead p-4">
          <div className="head"></div>

          <div className="imageinnertxt1 p-sm-5 p-4">
            <img
              className="logo1 img-fluid"
              data-aos="slide-up"
              alt="logo"
              src={require('../../asset/logo.png')}
            ></img>
            <img
              className="logoname1 img-fluid"
              data-aos="slide-right"
              alt="logo"
              src={require('../../asset/logoname.png')}
            ></img>
          </div>
        </div>
      </>

      <>
        <div className="textimg" data-aos="fade-up"></div>
        <div className="textimgtext" data-aos="fade-up">
          <h1 className="text-center">𝐂𝐀𝐓𝐄𝐆𝐎𝐑𝐈𝐄𝐒</h1>
        </div>

        <div className="d-flex flex-wrap p-2 justify-content-around bg-white main-categories pb-5">
          <Categories
            option={'one'}
            href={'/subcategories'}
            h1={`𝐌𝐚𝐫𝐫𝐢𝐚𝐠𝐞`}
            p={`The perfect decoration transforms a wedding into a magical, unforgettable event`}
            img="https://cdn0.weddingwire.in/article/9086/3_2/960/jpg/16809-creative-wedding-photography-avinash-dhoundhiyal-photography-lead-image.jpeg"
          />
          <Categories
            option={'two'}
            href={'/haldi'}
            h1={`𝐇𝐚𝐥𝐝𝐢 𝐃𝐞𝐜𝐨𝐫𝐚𝐭𝐢𝐨𝐧`}
            p={`The vibrant haldi celebration symbolizes growth, purity, and happiness.`}
            img="https://cdn0.weddingwire.in/article/5600/3_2/1280/jpg/90065-haldi-ceremony-rimi-sen-1.webp"
          />
          <Categories
            option={'three'}
            href={'/reception'}
            h1={`𝐑𝐞𝐜𝐞𝐩𝐭𝐢𝐨𝐧`}
            p={`Reception decorations should reflect the couple's unique love story.`}
            img="https://img.staticmb.com/mbcontent//images/uploads/2022/5/wedding%20stage%20decoration.jpg"
          />
          <Categories
            option={'four'}
            href={'/mantapa'}
            h1={`𝐌𝐚𝐧𝐭𝐚𝐩𝐚`}
            p={`A beautifully decorated mantapa sets the stage for a grand celebration.`}
            img="https://thumbs.dreamstime.com/b/bangalore-karnataka-india-oct-closeup-beautifully-decorated-big-golden-color-wedding-mandapa-mantapa-design-inside-hindu-199438377.jpg"
          />
          <Categories
            option={'five'}
            href={'/birthday'}
            h1={`𝐁𝐢𝐫𝐭𝐡𝐝𝐚𝐲 𝐄𝐯𝐞𝐧𝐭𝐬`}
            p={`Balloons, lights, and flowers enhance the celebrant's special day.`}
            img="https://media.istockphoto.com/id/918933880/photo/birthday-cake-on-a-table.jpg?s=612x612&w=0&k=20&c=pV3vReuTK2x4soVzHTj2hAfhVMbK0pZfNpjEeXo3ges="
          />
          <Categories
            option={'six'}
            href={'/naming'}
            h1={`𝐍𝐚𝐦𝐢𝐧𝐠 𝐂𝐞𝐫𝐞𝐦𝐨𝐧𝐲`}
            p={`The naming ceremony is a celebration of life, love, and hope for the future`}
            img="https://mymandap.in/wp-content/uploads/2021/12/23cbc7c9-74d2-4750-81ee-7c771fef6c24-scaled.jpg"
          />
          <Categories
            option={'seven'}
            href={'/keeth'}
            h1={`𝐂𝐡𝐚𝐩𝐩𝐚𝐫𝐚𝐦`}
            p={`Chapparam decoration's should be a reflection of the couple's unique cultural identity.`}
            img="https://i.pinimg.com/474x/a5/82/67/a58267eaf90f7decbc21b092db03d941.jpg"
          />
        </div>
      </>

      <div className="mx-auto p-md-4 p-2 col-xl-10 package">
        <h1 className="text-dark text-center mb-4">𝐒𝐞𝐥𝐞𝐜𝐭 𝐘𝐨𝐮𝐫 𝐏𝐚𝐜𝐤𝐚𝐠𝐞</h1>
        <div className="head-label ">
          <div className="pack-label">
            <img
              src="https://images.rawpixel.com/image_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvcm00NjdiYXRjaDUtc2NlbmUtdy0wMDItbDBha3pzejYuanBn.jpg?s=Q8ou_oc12RdR0CvRNnFlBeEhs-ezvTRuZV8tLeqT-iw"
              alt=""
              className="postion-absolute"
            />
            <label htmlFor="page1" className="tab ">
              𝗕𝗜𝗥𝗧𝗛𝗗𝗔𝗬
            </label>
          </div>
          <div className="pack-label">
            <img
              src="https://img.freepik.com/premium-photo/luxury-wedding-concept-wedding-rings-flowers_23-2148652001.jpg"
              alt=""
              className="postion-absolute"
            />
            <label htmlFor="page2" className="tab">
              𝗠𝗔𝗥𝗥𝗜𝗔𝗚𝗘
            </label>
          </div>
        </div>
        <input type="radio" name="page" id="page1" checked />
        <div className="content" id="content1">
          <h3 className="text-center text-dark">𝐁𝐈𝐑𝐓𝐇𝐃𝐀𝐘 𝐏𝐀𝐂𝐊𝐀𝐆𝐄𝐒</h3>
          <div className="main-parent">
            <div className="parent" id="parent1">
              <h5>𝐂𝐥𝐚𝐬𝐬𝐢𝐜 𝐂𝐞𝐥𝐞𝐛𝐫𝐚𝐭𝐢𝐨𝐧</h5>
              <div className="child hidden">
                <ul>
                  <li>Decoration</li>
                  <li>Cake - 3kg</li>
                  <li>Photography</li>
                  <li>Anchor</li>
                  <li>Games Activity</li>
                  <li>Light Music System</li>
                  <li className="text-decoration-none mb-4">
                    <strong className="fs-4">Total -25k</strong>
                  </li>
                  <li className="my-3">
                    <a href="/contactus#contact">For Inquiry</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="parent" id="parent2">
              <h5>𝐌𝐨𝐦𝐞𝐧𝐭𝐬 𝐏𝐚𝐜𝐤𝐚𝐠𝐞</h5>
              <div className="child hidden">
                <ul>
                  <li>Decoration</li>
                  <li>Cake - 4.5kg</li>
                  <li>Photography</li>
                  <li>Anchor</li>
                  <li>Games Activity</li>
                  <li>Light music system</li>
                  <li>Tattoo Artist</li>
                  <li>Caricature</li>
                  <li>Cartoon Character</li>
                  <li>Popcorn</li>
                  <li>Cotton Candy</li>
                  <li>Cold Fire</li>
                  <li>Welcome Drink</li>
                  <li>Food For 50pax</li>
                  <li>
                    <strong className="fs-4">Total -50k</strong>
                  </li>
                  <li className="my-3">
                    <a href="/contactus#contact">For Inquiry</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="parent" id="parent3">
              <h5>𝐃𝐫𝐞𝐚𝐦 𝐏𝐚𝐜𝐤𝐚𝐠𝐞</h5>
              <div className="child hidden">
                <ul>
                  <li>Premium Decoration</li>
                  <li>Photo Booth</li>
                  <li>Cake - 6kg</li>
                  <li>Photography</li>
                  <li>Anchor</li>
                  <li>Games Activity</li>
                  <li>Light Music System</li>
                  <li>Tattoo Artist</li>
                  <li>Caricature</li>
                  <li>Cartoon Character</li>
                  <li>Balloon Shooting</li>
                  <li>Cold Fire</li>
                  <li>Smoke Entry</li>
                  <li>Popcorn</li>
                  <li>Cotton Candy</li>
                  <li>Soda</li>
                  <li>Welcome Girls</li>
                  <li>Welcome Drink</li>
                  <li>Food For 150pax</li>
                  <li>
                    <strong className="fs-4">Total- 75k</strong>
                  </li>
                  <li className="my-3">
                    <a href="/contactus#contact">For Inquiry</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <input type="radio" name="page" id="page2" />
        <div className="content" id="content2">
          <h3 className="text-center text-dark">𝐌𝐀𝐑𝐑𝐈𝐀𝐆𝐄 𝐏𝐀𝐂𝐊𝐀𝐆𝐄𝐒</h3>
          <div className="main-parent">
            <div className="parent " id="parent1">
              <h5>𝐂𝐥𝐚𝐬𝐬𝐢𝐜 𝐏𝐚𝐜𝐤𝐚𝐠𝐞</h5>
              <div className="child hidden">
                <ul>
                  <h4>𝐆𝐞𝐧𝐞𝐫𝐚𝐥 𝐏𝐚𝐜𝐤𝐚𝐠𝐞</h4>
                  <li>Haldi</li>
                  <li>Backdrop</li>
                  <li>Mantap Decoration</li>
                  <li>Photo Booth</li>
                  <li>Entrance Decoration</li>
                  <li>Photography</li>
                  <li>Videography</li>
                  <li>Makeup & Mehendi</li>
                  <li>Welcome Girls</li>
                  <li>Cold Fire</li>
                  <li>Light music</li>
                  <li>
                    <strong className="fs-5">Total - 1Lakh</strong>
                  </li>
                  <li className="my-3">
                    <a href="/contactus#contact">For Inquiry</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="parent " id="parent2">
              <h5>𝐌𝐨𝐦𝐞𝐧𝐭𝐬 𝐏𝐚𝐜𝐤𝐚𝐠𝐞</h5>
              <div className="child hidden">
                <ul>
                  <h4>𝐆𝐞𝐧𝐞𝐫𝐚𝐥 𝐃𝐞𝐜𝐨𝐫𝐚𝐭𝐢𝐨𝐧 𝐖𝐢𝐭𝐡 𝐅𝐮𝐫𝐧𝐢𝐭𝐮𝐫𝐞</h4>
                  <li>Haldi</li>
                  <li>Backdrop</li>
                  <li>Mantap Decoration</li>
                  <li>Photo Booth</li>
                  <li className="d-flex">
                    <span>Entrance Path Way Decoration </span>
                  </li>
                  <li className="d-flex">
                    <span>
                      stage setup furniture for 100Pax with dinning table
                    </span>
                  </li>
                  <li>Photography</li>
                  <li>Videography</li>
                  <li>Makeup & Mehendi</li>
                  <li>Welcome Girls</li>
                  <li>Cold Fire</li>
                  <li>Light music</li>
                  <li>
                    <strong className="fs-5">Total - 1.5Lakh</strong>
                  </li>
                  <li className="my-3">
                    <a href="/contactus#contact">For Inquiry</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="parent " id="parent3">
              <h5>𝐁𝐥𝐢𝐬𝐬𝐟𝐮𝐥𝐥 𝐏𝐚𝐜𝐤𝐚𝐠𝐞</h5>
              <div className="child hidden">
                <ul>
                  <h4>𝐂𝐨𝐦𝐩𝐥𝐞𝐭𝐞 𝐒𝐞𝐭𝐮𝐩 𝐃𝐞𝐜𝐨𝐫𝐚𝐭𝐢𝐨𝐧</h4>
                  <li>Haldi</li>
                  <li>Backdrop</li>
                  <li>Mantap Decoration</li>
                  <li>Photo Booth</li>
                  <li className="d-flex">
                    <span>Entrance Path Way Decoration </span>
                  </li>
                  <li className="d-flex">
                    <span>
                      stage setup furniture for 200Pax with dinning table
                    </span>
                  </li>
                  <li>Photography</li>
                  <li>Videography</li>
                  <li>Makeup & Mehendi</li>
                  <li>Welcome Girls</li>
                  <li>Cold Fire</li>
                  <li>Light music</li>
                  <li>Power Service</li>
                  <li>Bride & Groom Entry</li>
                  <li>
                    <strong className="fs-5">Total - 2Lakh</strong>
                  </li>
                  <li className="my-3">
                    <a href="/contactus#contact">For Inquiry</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="parent " id="parent4">
              <h5>𝐂𝐞𝐥𝐞𝐛𝐫𝐚𝐭𝐢𝐨𝐧 𝐏𝐚𝐜𝐤𝐚𝐠𝐞</h5>
              <div className="child hidden">
                <ul>
                  <h4>𝐂𝐨𝐦𝐩𝐥𝐞𝐭𝐞 𝐒𝐞𝐭𝐮𝐩 𝐃𝐞𝐜𝐨𝐫𝐚𝐭𝐢𝐨𝐧 𝐖𝐢𝐭𝐡 𝐅𝐨𝐨𝐝</h4>
                  <li>Haldi</li>
                  <li>Backdrop</li>
                  <li>Mantap Decoration</li>
                  <li>Photo Booth</li>
                  <li>Sangeeth Setup</li>
                  <li>Light Setup</li>
                  <li>Sound Setup</li>
                  <li className="d-flex">
                    <span>Entrance Path Way Decoration </span>
                  </li>
                  <li className="d-flex">
                    <span>
                      stage setup furniture for 200Pax with dinning table
                    </span>
                  </li>
                  <li>Photography</li>
                  <li>Videography</li>
                  <li>Makeup & Mehendi</li>
                  <li>Emcee</li>
                  <li>Bride & Groom Entry</li>
                  <li>Welcome Girls</li>
                  <li>Welcome Drink</li>
                  <li>Cold Fire</li>
                  <li>Light music</li>
                  <li>Power Service</li>
                  <li>Starters 3variety</li>
                  <li>Dinner 300 pax</li>
                  <li>Breakfast 200pax</li>
                  <li>
                    <strong className="fs-5">Total - 2.5Lakh</strong>
                  </li>
                  <li className="my-3">
                    <a href="/contactus#contact">For Inquiry</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="scroll">
        <div
          data-aos="fade-up"
          className="container infinite-scroll mt-5 "
          id="container"
        >
          <div className="scroll-img ">
            <img src={require('../../asset/scroll/scroll1.png')} alt="works" />
            <img src={require('../../asset/scroll/scroll2.png')} alt="works" />
            <img src={require('../../asset/scroll/scroll3.png')} alt="works" />
            <img src={require('../../asset/scroll/scroll4.png')} alt="works" />
            <img src={require('../../asset/scroll/scroll5.png')} alt="works" />
            <img src={require('../../asset/scroll/scroll6.png')} alt="works" />
            <img src={require('../../asset/scroll/scroll7.png')} alt="works" />
            <img src={require('../../asset/scroll/scroll8.png')} alt="works" />
            <img src={require('../../asset/scroll/scroll9.png')} alt="works" />
            <img src={require('../../asset/scroll/scroll10.png')} alt="works" />
          </div>
        </div>
        <div
          data-aos="fade-up"
          className="container infinite-scroll "
          id="container"
        >
          <div className="scroll-img-one">
            <img src={require('../../asset/scroll/scroll11.png')} alt="works" />
            <img src={require('../../asset/scroll/scroll12.png')} alt="works" />
            <img src={require('../../asset/scroll/scroll21.png')} alt="works" />
            <img src={require('../../asset/scroll/scroll14.png')} alt="works" />
            <img src={require('../../asset/scroll/scroll15.png')} alt="works" />
            <img src={require('../../asset/scroll/scroll16.png')} alt="works" />
            <img src={require('../../asset/scroll/scroll17.png')} alt="works" />
            <img src={require('../../asset/scroll/scroll18.png')} alt="works" />
            <img src={require('../../asset/scroll/scroll19.png')} alt="works" />
            <img src={require('../../asset/scroll/scroll20.png')} alt="works" />
            <img src={require('../../asset/scroll/scroll13.png')} alt="works" />
          </div>
        </div>
      </div>
      <div className="banner">
        <div className="bannerimg"></div>
        <div className="bannertext">
          <div data-aos="fade-up">
            <h1 data-aos="fade-up">𝐇𝐈 !!!</h1>
            <p data-aos="fade-up">
              THINKING ABOUT PERSONLAZIED HELP FOR YOUR SPECIAL OCCASION ?
            </p>
            <p data-aos="fade-up">
              CHILL, WE'LL BE THERE FOR YOU TO "TAKE CARE" YOUR TENSION OUT.
            </p>
            <p data-aos="fade-up">
              WE ARE HERE TO TRANSFORM YOUR DREAM OCCASIONS TO REALITY.
            </p>
            <p data-aos="fade-up">
              WE PROMISE TO GIVE OUR "BEST SERVICE" IN AFFORDABLE PRICE FOR YOU.
            </p>
          </div>
        </div>
      </div>

      <>
        <div className="textimg mt-md-4" data-aos="fade-up"></div>
        <div className="textimgtext mt-md-4" data-aos="fade-up">
          <h1 className="text-center ">𝐎𝐔𝐑 𝐒𝐄𝐑𝐕𝐈𝐂𝐄𝐒 </h1>
        </div>

        <div className="container-fluid bg-white mx-0 px-0  mb-5">
          <div
            className="container bg-white services1 px-0"
            data-aos="slide-up"
          >
            <Services
              serviceimg={
                'https://i.pinimg.com/564x/6a/c2/8d/6ac28d1342af096b2b22891837f683e2.jpg'
              }
              servicename={'BRIDAL MAKEUP'}
            />
            <Services
              serviceimg={
                'https://i.pinimg.com/564x/05/bc/7b/05bc7bbc078c5812c4b4f8d4163b0ca3.jpg'
              }
              servicename={'MENHDI ARTIST'}
            />

            <Services
              serviceimg={
                'https://i.pinimg.com/564x/f9/71/34/f9713496205c88f4d5d2fd5c919301c7.jpg'
              }
              servicename={'TENT'}
            />
            <Services
              serviceimg={
                'https://i.pinimg.com/564x/9c/17/08/9c1708435fb5cf5ff72e84de4061a5f7.jpg'
              }
              servicename={'CATERING'}
            />
            <Services
              serviceimg={require('../../asset/services/photo.png')}
              servicename={'PHOTOGRAPHY'}
            />
            <Services
              serviceimg={
                'https://i.pinimg.com/564x/c5/17/22/c5172269a589c11685ec623b3bf0b2d6.jpg'
              }
              servicename={'CORPORATE EVENT'}
            />
            <Services
              serviceimg={
                'https://www.bax-shop.nl/blog/wp-content/uploads/2019/06/blog_line_arrays.jpg'
              }
              servicename={'SOUND SYSTEM'}
            />

            <Services
              serviceimg={require('../../asset/services/lighting-decoration.png')}
              servicename={'LIGHTING'}
            />
            <Services
              serviceimg={
                'https://5.imimg.com/data5/SELLER/Default/2021/12/NH/KD/JH/140986782/125kva-koel-diesel-generator-500x500.jpg'
              }
              servicename={'GENERATOR'}
            />
            <Services
              serviceimg={require('../../asset/services/services10.png')}
              servicename={'GERMAN TENT'}
            />
            <Services
              serviceimg={
                'https://i.pinimg.com/564x/02/a2/0f/02a20f358623bc94240154c098501dfb.jpg'
              }
              servicename={'LED WALL'}
            />
            <Services
              serviceimg={require('../../asset/services/caricature.png')}
              servicename={'CARICATURE, CARTOON'}
            />
            <Services
              serviceimg={require('../../asset/services/best-amusement-park.jpg')}
              servicename={'AMUSMENTS'}
            />
            <Services
              serviceimg={require('../../asset/services/exhibition-organising.png')}
              servicename={'EXHIBITION ORGANISING'}
            />

            <Services
              serviceimg={require('../../asset/services/tatoo.png')}
              servicename={'TATOO, MAGICIAN'}
            />

            {/* <Services
                            serviceimg={require('../../asset/services/services12.png')}
                            servicename={'EXHIBITION'}
                        />
                        <Services
                            serviceimg={require('../../asset/services/services13.png')}
                            servicename={'EXHIBITION'}
                        /> */}
          </div>
        </div>
      </>

      <div className="main-banner my-5 d-flex align-items-center">
        <div className="banner1textin1 mx-auto col-md-6 col-11 d-flex flex-column align-items-center">
          <img
            className="img-flui"
            data-aos="slide-up"
            src={require('../../asset/planner-logo.png')}
            alt="logo"
            height="100px"
          />

          <p className="m-2" data-aos="fade-up">
            𝐁𝐄𝐇𝐀𝐋𝐅
          </p>
          <p className="m-2" data-aos="fade-up">
            𝐎𝐅
          </p>
          <p className="m-2" data-aos="fade-up">
            "𝐑𝐎𝐘𝐀𝐋 𝐒𝐎𝐔𝐍𝐃 𝐒𝐘𝐒𝐓𝐄𝐌"
          </p>
          <p className="m-2" data-aos="fade-up">
            𝐒𝐈𝐍𝐂𝐄 𝟏𝟗𝟕𝟔
          </p>
        </div>
      </div>

      <Plannerfooter />
    </div>
  )
}
