import { Categories, Social } from '../../palnnerhome/Plannerdashbord'
import { Plannerfooter } from '../../plannerfooter/Plannerfooter'
import { Plannerheader } from '../../plannerhead/Plannerhead'
import React from 'react'

export function Footercatergories() {
  return (
    <div>
      <Plannerheader />
      <Social />

      <>
        <div className="textimg" data-aos="fade-up"></div>
        <div className="textimgtext" data-aos="fade-up">
          <h1 className="text-center">𝐂𝐀𝐓𝐄𝐆𝐎𝐑𝐈𝐄𝐒</h1>
        </div>

        <div className="d-flex flex-wrap p-2 justify-content-around bg-white main-categories pb-5">
          <Categories
            option={'one'}
            href={'/subcategories'}
            h1={`𝐌𝐚𝐫𝐫𝐢𝐚𝐠𝐞`}
            p={`The perfect decoration transforms a wedding into a magical, unforgettable event`}
            img="https://cdn0.weddingwire.in/article/9086/3_2/960/jpg/16809-creative-wedding-photography-avinash-dhoundhiyal-photography-lead-image.jpeg"
          />
          {/* <Categories
            option={"two"}
            href={"/haldi"}
            h1={`𝐇𝐚𝐥𝐝𝐢 𝐃𝐞𝐜𝐨𝐫𝐚𝐭𝐢𝐨𝐧`}
            p={`The vibrant haldi celebration symbolizes growth, purity, and happiness.`}
            img="https://cdn0.weddingwire.in/article/5600/3_2/1280/jpg/90065-haldi-ceremony-rimi-sen-1.webp"
          />
          <Categories
            option={"three"}
            href={"/reception"}
            h1={`𝐑𝐞𝐜𝐞𝐩𝐭𝐢𝐨𝐧`}
            p={`Reception decorations should reflect the couple's unique love story.`}
            img="https://img.staticmb.com/mbcontent//images/uploads/2022/5/wedding%20stage%20decoration.jpg"
          />
          <Categories
            option={"four"}
            href={"/mantapa"}
            h1={`𝐌𝐚𝐧𝐭𝐚𝐩𝐚`}
            p={`A beautifully decorated mantapa sets the stage for a grand celebration.`}
            img="https://i.fabweddings.in/storage/gallery-photos/wedding-decor-mandap-decor-bridal-portraits-decoration-arch-architecture.jpg"
          /> */}
          <Categories
            option={'five'}
            href={'/birthday'}
            h1={`𝐁𝐢𝐫𝐭𝐡𝐝𝐚𝐲 𝐄𝐯𝐞𝐧𝐭𝐬`}
            p={`Balloons, lights, and flowers enhance the celebrant's special day.`}
            img="https://media.istockphoto.com/id/918933880/photo/birthday-cake-on-a-table.jpg?s=612x612&w=0&k=20&c=pV3vReuTK2x4soVzHTj2hAfhVMbK0pZfNpjEeXo3ges="
          />
          <Categories
            option={'six'}
            href={'/naming'}
            h1={`𝐍𝐚𝐦𝐢𝐧𝐠 𝐂𝐞𝐫𝐞𝐦𝐨𝐧𝐲`}
            p={`The naming ceremony is a celebration of life, love, and hope for the future`}
            img="https://mymandap.in/wp-content/uploads/2021/12/23cbc7c9-74d2-4750-81ee-7c771fef6c24-scaled.jpg"
          />
          <Categories
            option={'seven'}
            href={'/keeth'}
            h1={`𝐂𝐡𝐚𝐩𝐩𝐚𝐫𝐚𝐦`}
            p={`Chapparam decorations should be a reflection of the couple's unique cultural identity.`}
            img="https://i.pinimg.com/474x/a5/82/67/a58267eaf90f7decbc21b092db03d941.jpg"
          />
        </div>
      </>
      <Plannerfooter />
    </div>
  )
}
