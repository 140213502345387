import '../categories/Categories.styles.css'
import { Social } from '../palnnerhome/Plannerdashbord'
import { Plannerfooter } from '../plannerfooter/Plannerfooter'
import { Plannerheader } from '../plannerhead/Plannerhead'
import Categoriesimg from './Birthday'
import React from 'react'

export function Keeth() {
  return (
    <div className="catergories">
      <Plannerheader></Plannerheader>
      <Social />

      <div className="col-sm-12 ">
        <div className="keeth col-12"></div>
        <div className="col-12  catergoriesimg">
          <h1 className="col">𝐂𝐇𝐀𝐏𝐏𝐀𝐑𝐀 / 𝐏𝐀𝐂𝐇𝐀 𝐏𝐀𝐍𝐃𝐀𝐋</h1>

          <div className="container p-0">
            <div className="row  mx-0 firstrow mt-1 g-4 ">
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara1.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara2.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara3.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara4.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara5.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara6.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara7.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara8.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara9.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara10.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara21.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara12.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara13.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara14.png')}
              />
              {/* <Categoriesimg
                categoriesimg={require("../../asset/Chapara/chapara15.png")}
              />
              <Categoriesimg
                categoriesimg={require("../../asset/Chapara/chapara16.png")}
              />
              <Categoriesimg
                categoriesimg={require("../../asset/Chapara/chapara17.png")}
              />
              <Categoriesimg
                categoriesimg={require("../../asset/Chapara/chapara18.png")}
              />
              <Categoriesimg
                categoriesimg={require("../../asset/Chapara/chapara19.png")}
              />
              <Categoriesimg
                categoriesimg={require("../../asset/Chapara/chapara20.png")}
              />
              <Categoriesimg
                categoriesimg={require("../../asset/Chapara/chapara21.png")}
              /> */}
              {/* <Categoriesimg
                categoriesimg={require("../../asset/Chapara/chapara22.png")}
              /> */}
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara23.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara24.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara25.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara26.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara27.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara28.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara29.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara30.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara31.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara32.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara33.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara34.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara35.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara36.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara37.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara38.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara39.png')}
              />
              <Categoriesimg
                categoriesimg={require('../../asset/Chapara/chapara40.png')}
              />
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>

      <Plannerfooter></Plannerfooter>
    </div>
  )
}
