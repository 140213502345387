import './Comingsoon.styles.css'
import React from 'react'

export function Comingsoon() {
  return (
    <div className="col-sm-12">
      <div className="row coming mx-0">
        <div className="col-sm-6 comeimgimg">
          <img
            alt="Coming-soon"
            src={require('../../asset/img/coming-soon.png')}
            className="img-fluid"
          />
        </div>
        <div className="col-sm-6">
          <img
            alt="Coming-soon"
            src={require('../../asset/img/coming-soon-text.png')}
            className="img-fluid"
          />
        </div>
      </div>
    </div>
  )
}
